import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { DeviceListLevel } from 'constants/enums.js';
import FormInput from 'components/FormInput.jsx';
import { doNotAllowSpaceAsFirstCharacter } from 'infrastructure/helpers/commonHelpers.js';
import { configurationTypeIds } from 'constants/integrationEnums.js';
import translate from 'i18n-translations/translate.jsx';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { getHospitalDepartments } from 'api/healthSystems.js';

const TranslationServicesModal = ({ itemToEdit, setConfigParams, configurationTypeId, fieldErrors, setError }) => {
	const intl = useIntl();
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const [hospitals, setHospitals] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [floors, setFloors] = useState([]);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(itemToEdit?.healthSystem ?? { value: '0', label: 'All' });
	const [selectedHospital, setSelectedHospital] = useState(itemToEdit?.hospital ?? { value: '0', label: 'All' });
	const [selectedDepartment, setSelectedDepartment] = useState(itemToEdit?.department ?? { value: '0', label: 'All' });
	const [selectedFloor, setSelectedFloor] = useState(itemToEdit?.floor ?? { value: '0', label: 'All' });
	const [isLoading, setIsLoading] = useState(true);
	const [floorToFilter, setFloorToFilter] = useState([]);
	const [fields, setFields] = useState({});
	const [isInitial, setIsInitial] = useState(true);

	useEffect(() => {
		const fetchHospitals = async () => {
			let hsHospitals = [];
			if (selectedHealthSystem.value !== '0' && allHealthSystems.find(item => item.id === selectedHealthSystem.value)) {
				setIsLoading(true);
				const res = await getHealthSystemHospitals(selectedHealthSystem.value);
				if (res.error) {
					setError(res.error.message);
				} else {
					hsHospitals = res;
				}
			}
			setHospitals(hsHospitals);
			setIsLoading(false);
			if (!isInitial) {
				setSelectedHospital({ value: '0', label: 'All' });
				setSelectedDepartment({ value: '0', label: 'All' });
				setSelectedFloor({ value: '0', label: 'All' });
			}
			setIsLoading(false);
		};
		fetchHospitals();
	}, [selectedHealthSystem]);

	useEffect(() => {
		const fetchDepartments = async () => {
			if (!selectedHospital || selectedHospital.value === '0') {
				return;
			}
			setIsLoading(true);
			let departmentsRes = [];
			const res = await getHospitalDepartments(selectedHealthSystem.value, selectedHospital.value);
			if (res.error) {
				setError(res.error.message);
			} else {
				departmentsRes = res?.hospital?.departments ?? [];
				let result = [];
				departmentsRes.forEach(item => {
					result = [...result, ...item.floors.map(floor => ({ ...floor, parentId: item.id }))];
				});
				setFloorToFilter(result);
			}
			setDepartments(departmentsRes);
			setIsLoading(false);
			if (!isInitial) {
				setSelectedDepartment({ value: '0', label: 'All' });
				setSelectedFloor({ value: '0', label: 'All' });
			}
		};
		fetchDepartments();
	}, [selectedHospital]);

	useEffect(() => {
		const filteredFloors = floorToFilter.filter(item => item.parentId === selectedDepartment.value);
		setFloors([...filteredFloors.map(item => ({ ...item }))]);
		if (!isInitial) {
			setSelectedFloor({ value: '0', label: 'All' });
		}
		setIsInitial(false);
	}, [selectedDepartment]);

	const transformArray = array => {
		if (!array) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		return newArray;
	};

	const onHealthSystemChange = async event => {
		const { value, label } = event;
		const hsHospitals = value !== '0' ? await getHealthSystemHospitals(value) : [];
		if (hsHospitals.error) {
			setError(hsHospitals.error.message);
		} else {
			const hospitalArray = hsHospitals.map(hospital => ({ value: hospital.id, label: hospital.name }));
			setHospitals(hospitalArray);
			setDepartments([]);
			setFloors([]);
		}
		setSelectedHealthSystem({ value, label });
	};

	useEffect(() => {
		const node = getNode();
		setConfigParams({
			configurationTypeId,
			nodeName: node.label,
			nodeLevel: node.level,
			nodeId: node.id,
			parentNodeId: node.parentId,
			configJson: JSON.stringify({
				...fields,
				healthSystem: selectedHealthSystem,
				hospital: selectedHospital,
				department: selectedDepartment,
				floor: selectedFloor,
			}),
		});
	}, [selectedHealthSystem, selectedHospital, selectedDepartment, selectedFloor, configurationTypeId, fields]);

	const getNode = () => {
		if (selectedFloor.value !== '0') {
			return {
				id: selectedFloor.value,
				label: `${selectedHealthSystem.label} > ${selectedHospital.label} > ${selectedDepartment.label} > ${selectedFloor.label}`,
				parentId: selectedDepartment.value,
				level: DeviceListLevel.FLOOR,
			};
		}
		if (selectedDepartment.value !== '0') {
			return {
				id: selectedDepartment.value,
				label: `${selectedHealthSystem.label} > ${selectedHospital.label} > ${selectedDepartment.label}`,
				parentId: selectedHospital.value,
				level: DeviceListLevel.DEPARTMENT,
			};
		}
		if (selectedHospital.value !== '0') {
			return {
				id: selectedHospital.value,
				label: `${selectedHealthSystem.label} > ${selectedHospital.label}`,
				parentId: selectedHealthSystem.value,
				level: DeviceListLevel.HOSPITAL,
			};
		}
		if (selectedHealthSystem.value !== '0') {
			return {
				id: selectedHealthSystem.value,
				label: selectedHealthSystem.label,
				parentId: null,
				level: DeviceListLevel.HEALTH_SYSTEM,
			};
		}
		return { id: null, label: null, parentId: null, level: null };
	};

	const handleOnFieldChange = fields => {
		setFields({ ...fields });
	};

	return (
		<>
			<div className='input'>
				<p className='label'>{translate('selectHealthSystem')}</p>
				<p className='font-14'>{translate('selectHSForConfiguration')}</p>
				<Select
					isDisabled={isLoading}
					value={selectedHealthSystem}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
					classNamePrefix='react-select'
					options={transformArray(allHealthSystems)}
					onChange={onHealthSystemChange}
				/>
			</div>
			<div className='input'>
				<p className='label'>{translate('selectHospital')}</p>
				<p className='font-14'>{translate('chooseHospitalForChanges')}</p>
				<Select
					isLoading={isLoading}
					isDisabled={isLoading}
					value={selectedHospital}
					placeholder={intl.formatMessage({ id: 'selectHospital' })}
					classNamePrefix='react-select'
					options={transformArray(hospitals)}
					onChange={setSelectedHospital}
				/>
			</div>
			<div className='input'>
				<p className='label'>{translate('selectDepartment')}</p>
				<p className='font-14'>
					{translate('chooseSectorForChanges', {
						value: intl.formatMessage({ id: 'department' }),
					})}
				</p>
				<Select
					isDisabled={isLoading}
					value={selectedDepartment}
					placeholder={intl.formatMessage({ id: 'selectDepartment' })}
					classNamePrefix='react-select'
					options={transformArray(departments)}
					onChange={setSelectedDepartment}
				/>
			</div>
			<div className='input'>
				<p className='label'>{translate('selectFloor')}</p>
				<p className='font-14'>
					{translate('chooseSectorForChanges', {
						value: intl.formatMessage({ id: 'floor' }),
					})}
				</p>
				<Select
					isDisabled={isLoading}
					value={selectedFloor}
					placeholder={intl.formatMessage({ id: 'selectFloor' })}
					classNamePrefix='react-select'
					options={transformArray(floors)}
					onChange={setSelectedFloor}
				/>
			</div>
			{configurationTypeId === configurationTypeIds.GLOBO.id && (
				<Globo fieldErrors={fieldErrors} itemToEdit={itemToEdit} handleOnFieldChange={handleOnFieldChange} />
			)}
			{configurationTypeId === configurationTypeIds.LANGUAGE_LINE.id && (
				<LanguageLine fieldErrors={fieldErrors} itemToEdit={itemToEdit} handleOnFieldChange={handleOnFieldChange} />
			)}
			{configurationTypeId === configurationTypeIds.AMN.id && (
				<AMN fieldErrors={fieldErrors} itemToEdit={itemToEdit} handleOnFieldChange={handleOnFieldChange} />
			)}
			{configurationTypeId === configurationTypeIds.VOYCE.id && (
				<Voyce fieldErrors={fieldErrors} itemToEdit={itemToEdit} handleOnFieldChange={handleOnFieldChange} />
			)}
		</>
	);
};

const Globo = ({ fieldErrors, handleOnFieldChange, itemToEdit }) => {
	const intl = useIntl();
	const [authToken, setAuthToken] = useState(itemToEdit?.authToken ?? null);
	const [sharedToken, setSharedToken] = useState(itemToEdit?.sharedToken ?? null);
	const [authSecret, setAuthSecret] = useState(itemToEdit?.authSecret ?? null);
	const callTypeOptions = [
		{ value: 1, label: intl.formatMessage({ id: 'videoAndAudioOption' }) },
		{ value: 2, label: intl.formatMessage({ id: 'audioOnlyOption' }) },
	];
	const [callType, setCallType] = useState(
		itemToEdit?.callType ? callTypeOptions.find(item => itemToEdit.callType === item.value) : null
	);

	useEffect(() => {
		handleOnFieldChange({ authToken, sharedToken, authSecret, baseUrl: 'https://globodemo.com/', callType: callType?.value });
	}, [authToken, sharedToken, authSecret, callType]);

	return (
		<>
			<div className='input'>
				<FormInput
					text='AUTH_TOKEN*'
					id='authToken'
					placeholder='AUTH_TOKEN'
					type='text'
					value={authToken}
					onChange={event => setAuthToken(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.authToken}
					touched={fieldErrors?.authToken}
				/>
			</div>
			<div className='input'>
				<FormInput
					text='SHARED_TOKEN*'
					id='sharedToken'
					placeholder='SHARED_TOKEN'
					type='text'
					value={sharedToken}
					onChange={event => setSharedToken(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.sharedToken}
					touched={fieldErrors?.sharedToken}
				/>
			</div>
			<div className='input'>
				<FormInput
					text='AUTH_SECRET*'
					id='authSecret'
					placeholder='AUTH_SECRET'
					type='text'
					value={authSecret}
					onChange={event => setAuthSecret(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.authSecret}
					touched={fieldErrors?.authSecret}
				/>
			</div>
			<CallType callTypeOptions={callTypeOptions} fieldErrors={fieldErrors} callType={callType} setCallType={setCallType} />
		</>
	);
};

const LanguageLine = ({ fieldErrors, itemToEdit, handleOnFieldChange }) => {
	const [authCode, setAuthCode] = useState(itemToEdit?.authCode ?? null);
	const intl = useIntl();
	const callTypeOptions = [
		{ value: 1, label: intl.formatMessage({ id: 'videoAndAudioOption' }) },
		{ value: 2, label: intl.formatMessage({ id: 'audioOnlyOption' }) },
	];
	const [callType, setCallType] = useState(
		itemToEdit?.callType ? callTypeOptions.find(item => itemToEdit.callType === item.value) : null
	);

	useEffect(() => {
		handleOnFieldChange({ authCode, baseUrl: 'https://www.languageline.com', callType: callType?.value });
	}, [authCode, callType]);

	return (
		<>
			<div className='input'>
				<FormInput
					text='AUTH_CODE*'
					id='authCode'
					placeholder='AUTH_CODE'
					type='text'
					value={authCode}
					onChange={event => setAuthCode(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.authCode}
					touched={fieldErrors?.authCode}
				/>
			</div>
			<CallType callTypeOptions={callTypeOptions} fieldErrors={fieldErrors} callType={callType} setCallType={setCallType} />
		</>
	);
};

const AMN = ({ fieldErrors, itemToEdit, handleOnFieldChange }) => {
	const intl = useIntl();
	const [sipEndpoint, setSipEndpoint] = useState(itemToEdit?.sipEndpoint ?? null);
	const callTypeOptions = [
		{ value: 1, label: intl.formatMessage({ id: 'videoAndAudioOption' }) },
		{ value: 2, label: intl.formatMessage({ id: 'audioOnlyOption' }) },
	];
	const [callType, setCallType] = useState(
		itemToEdit?.callType ? callTypeOptions.find(item => itemToEdit.callType === item.value) : null
	);

	useEffect(() => {
		handleOnFieldChange({ sipEndpoint, baseUrl: 'https://www.amnhealthcare.com', callType: callType?.value });
	}, [sipEndpoint, callType]);

	return (
		<>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'sipEndpoint' })}*`}
					id='sipEndpoint'
					placeholder={intl.formatMessage({ id: 'sipEndpoint' })}
					type='text'
					value={sipEndpoint}
					onChange={event => setSipEndpoint(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.sipEndpoint}
					touched={fieldErrors?.sipEndpoint}
				/>
			</div>
			<CallType callTypeOptions={callTypeOptions} fieldErrors={fieldErrors} callType={callType} setCallType={setCallType} />
		</>
	);
};

const Voyce = ({ fieldErrors, itemToEdit, handleOnFieldChange }) => {
	const intl = useIntl();
	const callTypeOptions = [
		{ value: 1, label: intl.formatMessage({ id: 'videoAndAudioOption' }) },
		{ value: 2, label: intl.formatMessage({ id: 'audioOnlyOption' }) },
	];
	const [token, setToken] = useState(itemToEdit?.token ?? null);
	const [callType, setCallType] = useState(
		itemToEdit?.callType ? callTypeOptions.find(item => itemToEdit.callType === item.value) : null
	);

	useEffect(() => {
		handleOnFieldChange({ token, baseUrl: 'https://voyceglobal.com/', callType: callType?.value });
	}, [token, callType]);

	return (
		<>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'token' })}*`}
					id='token'
					placeholder={intl.formatMessage({ id: 'token' })}
					type='text'
					value={token}
					onChange={event => setToken(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.token}
					touched={fieldErrors?.token}
				/>
			</div>
			<CallType callTypeOptions={callTypeOptions} fieldErrors={fieldErrors} callType={callType} setCallType={setCallType} />
		</>
	);
};

const CallType = ({ callType, fieldErrors, setCallType, callTypeOptions }) => {
	const intl = useIntl();
	return (
		<div className='input'>
			<p className='label'>{`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
				id: 'defaultInterpreterCallType',
			})}`}</p>
			<p className='font-14'>{intl.formatMessage({ id: 'defaultInterpreterCallTypeDesc' })}</p>
			<Select
				value={callType}
				placeholder={intl.formatMessage({ id: 'callType' })}
				classNamePrefix='react-select'
				options={callTypeOptions}
				onChange={setCallType}
			/>
			<p className='red-error'>{fieldErrors?.callType}</p>
		</div>
	);
};

export default TranslationServicesModal;
